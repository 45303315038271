import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import arocklogo from './examples/icon/arocklogo23vittrans_200x200.png'
import '../style/CookiePopUp.scss';

const COOKIE_NAME = 'cc_cookie';

const CookiePopUp = () => {
    const [cookies, setCookie] = useCookies([COOKIE_NAME]);
    const [isVisible, setIsVisible] = useState(!cookies[COOKIE_NAME]);
    const [cookiePreferences, setCookiePreferences] = useState(
        cookies[COOKIE_NAME] || {
            categories: ["necessary"],
            level: ["necessary"],
            revision: 0,
            data: null,
            rfc_cookie: false,
            consent_date: new Date().toISOString(),
            last_consent_update: new Date().toISOString(),
        }
    );

    useEffect(() => {
        if (cookies[COOKIE_NAME]) {
            setCookiePreferences(cookies[COOKIE_NAME]);
        }
    }, [cookies]);

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isVisible]);

    const handleToggle = (type) => {
        setCookiePreferences((prev) => {
            const newPreferences = { ...prev };
            if (newPreferences.level.includes(type)) {
                newPreferences.level = newPreferences.level.filter((item) => item !== type);
            } else {
                newPreferences.level.push(type);
            }
            return newPreferences;
        });
    };

    const handleSave = () => {
        const updatedPreferences = {
            ...cookiePreferences,
            last_consent_update: new Date().toISOString(),
        };
        setCookie(COOKIE_NAME, updatedPreferences, { path: '/', expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
        setIsVisible(false);
    };

    const handleAcceptAll = () => {
        const updatedPreferences = {
            ...cookiePreferences,
            level: ["necessary", "analytics", "targeting", "marketing"],
            last_consent_update: new Date().toISOString(),
        };
        setCookie(COOKIE_NAME, updatedPreferences, { path: '/', expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <>
            <div className="cookie-popup">
                <div className="cookie-content">
                    <div className='cookie-popup-icon'>
                        <img src={arocklogo} alt="AROCK Logo" />
                    </div>
                    <div className='cookie-popup-title'>
                        <p>Du har kontroll över dina uppgifter</p>
                    </div>
                    <div className='cookie-popup-text'>
                        <p>
                            Genom att klicka på "acceptera alla cookies" samtycker du till lagring av cookies på din enhet
                            för att förbättra navigeringen på webbplatsen, analysera webbplatsens användning och bistå i
                            våra marknadsföringsinsatser.
                        </p>
                    </div>
                    <div className='cookie-popup-links'>
                        <p>Läs mer om kakor</p>
                        <p><a href="#">Googles sekretesspolicy</a></p>
                        <p>Visa detaljer</p>
                    </div>
                    <div className='cookie-popup-butttons'>
                        <button className='cookie-popup-buttonsave' onClick={handleSave}>Spara mina inställningar</button>
                        <button className='cookie-popup-buttonaccept' onClick={handleAcceptAll}>Acceptera alla cookies</button>
                    </div>
                    <div className="cookie-options">
                        <CookieSwitch label="Nödvändiga" value={cookiePreferences.level.includes("necessary")} onChange={() => handleToggle("necessary")} disabled />
                        <CookieSwitch label="Funktionella" value={cookiePreferences.level.includes("analytics")} onChange={() => handleToggle("analytics")} />
                        <CookieSwitch label="Statistiska" value={cookiePreferences.level.includes("targeting")} onChange={() => handleToggle("targeting")} />
                        <CookieSwitch label="Markering" value={cookiePreferences.level.includes("advertising")} onChange={() => handleToggle("advertising")} />
                    </div>
                </div>
            </div>
            {isVisible && <div className="overlay" />}
        </>
    );
};

export default CookiePopUp;

const CookieSwitch = ({ label, value, onChange, disabled }) => {
    return (
        <label className="switch-container">
            {label}
            <div className={`slider ${value ? 'on' : 'off'}`} onClick={!disabled ? onChange : undefined}>
                <div className="toggle" />
            </div>
        </label>
    );
};
