import { useState, useRef, useEffect } from "react";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export const CountryLanguageDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const countries = [
        {name: "Sweden", id: 205, iso: 'SE', language: "sv"},
        {name: "Norway", id: 160, iso: 'NO', language: "en"},
        {name: "Germany", id: 80, iso: 'DE', language: "en"},
        {name: "Italy", id: 105, iso: 'IT', language: "en"}
    ];
    const languages = [
        {name: "Swedish", id: 1, shortCode: 'sv', country: "SE"},
        {name: "English", id: 2, shortCode: 'en', country: props.activeCountry.iso}
    ];

    return (
        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    fontSize: "14px",
                    textTransform: "uppercase",
                    color: "white",
                    cursor: "pointer",
                    border: "1px solid white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    minWidth: "128px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                    backgroundColor: "transparent",
                    fontFamily: 'Monsterrat-Regular'
                }}
            >
                {props.activeCountry.name} / {props.activeLanguage.shortCode}
                {dropdownOpen ? <BsChevronDown /> : <BsChevronRight />}
            </div>

            {dropdownOpen && (
                <div
                    style={{
                        position: "absolute",
                        top: "100%",
                        marginTop: "7px",
                        left: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.59)",
                        color: "white",
                        borderRadius: "5px",
                        padding: "10px",
                        zIndex: 10,
                        minWidth: "137px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        fontFamily: 'Monsterrat-Regular'
                    }}
                >

                    <div style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "5px", opacity: 0.7 }}>
                        Land
                    </div>
                    {countries.map((country) => (
                        <div
                            key={country}
                            onClick={() => {
                                navigate('/' + country.language + '-' + country.iso + '/');
                                setDropdownOpen(false);
                            }}
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                textAlign: "left",
                                borderRadius: "3px",
                                transition: "background 0.2s",
                                backgroundColor: props.activeCountry.id === country.id ? "rgba(255, 255, 255, 0.2)" : "transparent",
                            }}
                            onMouseEnter={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.2)")}
                            onMouseLeave={(e) => (e.target.style.background = props.activeCountry.id === country.id ? "rgba(255, 255, 255, 0.2)" : "transparent")}
                        >
                            {country.name}
                        </div>
                    ))}


                    <hr style={{ margin: "8px 0", borderColor: "rgba(255, 255, 255, 0.2)" }} />


                    <div style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "5px", opacity: 0.7 }}>
                        Språk
                    </div>
                    {languages.map((language) => (
                        <div
                            key={language}
                            onClick={() => {
                                navigate('/' + language.shortCode + '-' + language.country + '/');
                                setDropdownOpen(false);
                            }}
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                textAlign: "left",
                                borderRadius: "3px",
                                transition: "background 0.2s",
                                backgroundColor: props.activeLanguage.id === language.id ? "rgba(255, 255, 255, 0.2)" : "transparent",
                            }}
                            onMouseEnter={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.2)")}
                            onMouseLeave={(e) => (e.target.style.background = props.activeLanguage.id === language.id ? "rgba(255, 255, 255, 0.2)" : "transparent")}
                        >
                            {language.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};