import React, { useState } from 'react';
import { BsSearch } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";

const SearchComponent = (props) => {
    const { handleShowSearch } = props
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const fetchFromApi = (value) => {
        fetch('/Api/System/Pim/IndexManager/SearchV2?search=' + value)
            .then(response => response.json())
            .then(data => { setResults(data.items); setShowDropdown(true); });
    };

    const handleSearch = (value) => {
        if (value.trim() !== '') {
            fetchFromApi(value);
        } else {
            setResults([]); // Clear results if the search input is empty
            setShowDropdown(false); // Hide dropdown when there are no results
        }
    };

    const handleProductClick = (productUri) => {
        navigate(productUri);
        handleShowSearch(false);
    };

    // Function to handle search query change
    const handleChange = (e) => {
        const { value } = e.target;
        setQuery(value);
        handleSearch(value);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', top: '15%'  }}>
            <div style={{ margin: '10px', position: 'relative', zIndex: 9999 }}>
                <BsSearch
                    size={20}
                    color="#888"
                    style={{
                        position: 'absolute',
                        left: '10px',
                        top: '45%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                    }}
                />
                <input
                    type="text"
                    value={query}
                    onChange={handleChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={t('pim-searchSubmit')}
                    style={{
                        height: '34px',
                        width: '408px',
                        fontSize: '16px',
                        padding: '0 10px',
                        marginBottom: '5px',
                        paddingLeft: '40px',
                        border: `1px solid ${isFocused ? 'black' : 'gray'}`,
                        outline: 'none',
                        transition: 'border-color 0.3s ease-in-out',
                    }}
                />
            </div>

            {showDropdown && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 11,
                        backgroundColor: 'rgba(0, 0, 0, 0.59)',
                        padding: '10px',
                        width: '408px',
                        zIndex: 9999,
                    }}
                >
                    <div
                        style={{
                            maxHeight: '215px',
                            overflowY: 'auto',
                            padding: '10px',
                            scrollbarWidth: 'thin',
                            scrollbarColor: 'white',
                            WebkitScrollbarWidth: '10px',
                        }}
                    >
                        {/* Display the seach result here */}
                        {results && results.map(result => (
                            <div key={result.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} onClick={() => handleProductClick(result.link)}>
                                <img
                                    src={result.image}
                                    alt={result.title}
                                    style={{ width: '80px', height: '100px', marginRight: '10px' }}
                                />
                                <div>
                                    <h3 style={{ fontSize: '12px', marginBottom: '5px', color: 'white' }}>{result.title}</h3>
                                    <p style={{ fontSize: '12px', color: 'white' }}>{result.price}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ marginTop: '20px', color: 'white' }}>
                        {/*
              <h2 style={{ fontSize: '15px', marginBottom: '5px', color: 'white', paddingLeft: '9px' }}>Dina senaste sök</h2>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>*/}
                        {/* Display pictures of products here */}
                        {/*
                {fakeProducts.slice(0, 4).map(product => (
                  <img
                    key={product.id}
                    src={product.image}
                    alt={product.title}
                    style={{ width: '86px', height: '107px', margin: '7px' }}
                  />
                ))}
                    
              </div>*/}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchComponent;
