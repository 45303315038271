import React, { useState, useEffect, useRef } from 'react';
import { BsBookmark, BsGem, BsBookmarkFill, BsChevronRight, BsChevronLeft, BsX } from "react-icons/bs";
import arrowProductLeft from './icon/arrowProduct.png';
import arrowProductRight from './icon/arrowProduct.png';
import { ProductColors } from '../ProductColors';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import '../../style/PimBrowser.scss';

const PimBrowser = ({ values, favorite, handleFavoriteQuantityChange }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [hoveredCard, setHoveredCard] = useState(null);
    const [favorites, setFavorites] = useState({});
    const [products, setProducts] = useState([]);
    const [currentIndexes, setCurrentIndexes] = useState([]);
    const [limit, setLimit] = useState(30);
    const [offset, setOffset] = useState(0);
    const sizeContainerRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);

    const [quickShopVisible, setQuickShopVisible] = useState(false);
    const [quickShopProduct, setQuickShopProduct] = useState(null);

    const handleFavoriteClick = (e, productId, isFavorite) => {
        e.stopPropagation();
        setFavorites((prevFavorites) => {
            const updatedFavorites = { ...prevFavorites, [productId]: !isFavorite };

            handleFavoriteQuantityChange(productId, isFavorite ? 'decrease' : 'increase');

            return updatedFavorites;
        });
    };

    useEffect(() => {
        if (favorite && favorite.products && Array.isArray(favorite.products)) {
            const favoriteIds = favorite.products.reduce((acc, item) => {
                acc[item.id] = true;
                return acc;
            }, {});
            setFavorites(favoriteIds);
        }
    }, [favorite]);

    const handlePreviousImage = (productIndex) => {
        setCurrentIndexes(prevIndexes =>
            prevIndexes.map((currentIndex, index) => {
                if (index === productIndex) {
                    const imageArray = products[productIndex]?.images || [];
                    return imageArray.length > 0 ? (currentIndex > 0 ? currentIndex - 1 : imageArray.length - 1) : 0;
                }
                return currentIndex;
            })
        );
    };

    const handleNextImage = (productIndex) => {
        setCurrentIndexes(prevIndexes =>
            prevIndexes.map((currentIndex, index) => {
                if (index === productIndex) {
                    const imageArray = products[productIndex]?.images || [];
                    return imageArray.length > 0 ? (currentIndex < imageArray.length - 1 ? currentIndex + 1 : 0) : 0;
                }
                return currentIndex;
            })
        );
    };

    useEffect(() => {
        if (sizeContainerRef.current) {
            const container = sizeContainerRef.current;
            setIsScrollable(container.scrollWidth > container.clientWidth);
        }
    }, [hoveredCard]);

    const scrollLeft = () => {
        if (sizeContainerRef.current) {
            sizeContainerRef.current.scrollBy({
                left: -100,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (sizeContainerRef.current) {
            sizeContainerRef.current.scrollBy({
                left: 100,
                behavior: 'smooth'
            });
        }
    };

    const handleProductClick = (productUri) => {
        navigate(productUri);
    };

    const [hasMoreProducts, setHasMoreProducts] = useState(true);

    const handleLoadMoreClick = () => {
        setOffset((prevValue) => prevValue + limit)
    };

    useEffect(() => {
        fetch('/Api/Widget/System/Pim/Browser/GetListedProductsJson?widgetId=' + values.id + '&limit=' + limit + '&offset=' + offset)
            .then((res) => res.json())
            .then((products) => {
                if (products.items.length < limit) {
                    setHasMoreProducts(false);
                }
                setProducts((prevArray) => [...prevArray, ...products.items]);
                setCurrentIndexes((prevArray) => [...prevArray, ...Array(products.items.length).fill(0)]);
            })
    }, [offset, limit])

    const QuickShopPopup = ({ product, onClose }) => {

        const [selectedSize, setSelectedSize] = useState("");
        const [dropdownOpen, setDropdownOpen] = useState(false);

        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
        const handleSizeSelect = (size) => {
            setSelectedSize(size);
            setDropdownOpen(false);
        };

        const colorPropertiesId = product.properties
            .filter(prop => prop.type === 'color')
            .map(prop => prop.propertyId);

        const sizeOptions = product.properties
            .filter(prop => prop.type === 'size')
            .map(prop => prop.value);

        const sizesArray = product.sizes ? Object.values(product.sizes) : [];

        return (
            <div className="QuickShopPopuppopup-content" onClick={(e) => e.stopPropagation()} >
                <div className='quickshop-header'>
                    <BsX
                        size={24}
                        className='quickshop-header-icon'
                        onClick={() => setQuickShopVisible(false)}
                    />
                </div>
                <p className='quickshop-color'>{t('shop-Colour')}</p>
                <div className="color-container">
                    {/* Displaying color circles based on propertyId */}
                    {colorPropertiesId.map((propertyId, index) => {
                        const color = ProductColors[propertyId];

                        if (color) {
                            // If it's an array (multiple colors), render each color as a split circle
                            if (Array.isArray(color)) {
                                return (
                                    <div
                                        key={index}
                                        className="color-circle-split"
                                        style={{
                                            background: `linear-gradient(to right, ${color[0]} 50%, ${color[1]} 50%)`
                                        }}
                                        aria-label={`Color: ${color[0]} / ${color[1]}`}
                                    />
                                );
                            }
                            // If it's a single color, render just that one color
                            return (
                                <div
                                    key={index}
                                    className="color-circle"
                                    style={{ backgroundColor: color }}
                                    aria-label={`Color: ${color}`}
                                />
                            );
                        }
                        return null;
                    })}
                </div>
                <p className='quickshop-size'>{t('pim-size')}</p>
                {sizesArray.length > 1 ? (
                    <div className="size-dropdown" onClick={toggleDropdown}>
                        <div className="dropdown-header">{selectedSize || "Välj storlek"}</div>
                        {dropdownOpen && (
                            <ul className="dropdown-list">
                                {sizesArray.map((size, index) => (
                                    <li key={index} onClick={() => handleSizeSelect(size.value)}>
                                        {size.value}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ) : (
                    <div className="size-dropdown">
                        <div className="dropdown-header">
                            {sizesArray.length === 1 ? sizesArray[0].value : sizeOptions[0]}
                        </div>
                    </div>
                )}

                <div className='quickshop-container'>
                    <button className='quickshop-button'>
                        {t('shop-addToCart')}
                        <BsGem className="gem-icon" />
                    </button>
                </div>
            </div>
        )
    };

    return (
        <>
            <div className="row">
                {/* 
                <div className="col-12"> 
                    <FilterComponent />
                </div>
                */}
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card-wrapper">
                        <div className="card-container">
                            {products.map((product, index) => {

                                const imageArray = Array.isArray(product.images) ? product.images : [];

                                const showImage = imageArray.length > 0;
                                const currentIndex = currentIndexes[index];

                                const colorPropertiesId = product.properties
                                    .filter(prop => prop.type === 'color')
                                    .map(prop => prop.propertyId);

                                const materialValue = product.properties
                                    .filter(prop => prop.type === 'material')
                                    .map(prop => prop.value);

                                const sizeValue = product.properties
                                    .filter(prop => prop.type === 'size')
                                    .map(prop => prop.value);

                                return (
                                    <div className="card-grid" key={product.id}
                                        onMouseEnter={() => setHoveredCard(index)}
                                        onMouseLeave={() => { setHoveredCard(null), setQuickShopVisible(false) }}
                                    >
                                        <div className="card" onClick={() => handleProductClick(product.uri)}>
                                            <div className="card-header">
                                                {imageArray.length > 1 && (
                                                    <>
                                                        <div className="arrow-wrapper" onClick={(e) => { handlePreviousImage(index); e.stopPropagation(); }}>
                                                            <img
                                                                src={arrowProductLeft}
                                                                alt="Arrow Left"
                                                                className="arrow-image-left"
                                                                style={{ transform: 'rotate(180deg)' }}
                                                            />
                                                        </div>
                                                        <div className="arrow-wrapper" onClick={(e) => { handleNextImage(index); e.stopPropagation(); }}>
                                                            <img
                                                                src={arrowProductRight}
                                                                alt="Arrow Right"
                                                                className="arrow-image-right"
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {showImage && (
                                                <div className="card-image-container">
                                                    <img
                                                        src={imageArray[currentIndex]}
                                                        alt={product.name}
                                                    />
                                                </div>
                                            )}
                                            <div className='card-footer'>
                                                <span className="icon-bookmark" onClick={(e) => handleFavoriteClick(e, product.id, favorites[product.id])}>
                                                    {favorites[product.id] ? <BsBookmarkFill className="favorite-icon filled" /> : <BsBookmark className="favorite-icon" />}
                                                </span>
                                                {/*
                                                    <span className="text">NYHET</span>
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setQuickShopProduct(product);
                                                        setQuickShopVisible(true);
                                                    }}
                                                */}
                                                <span className="icon-gem">
                                                    <BsGem />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="card-details-footer">
                                            <p className="card-material">{materialValue}</p>
                                            <h2 className="card-title">{product.name}</h2>
                                            {hoveredCard === index ? (
                                                <div className="card-size-container">
                                                    {product.sizes && Object.values(product.sizes).length > 0 ? (
                                                        <>
                                                            {isScrollable && (
                                                                <BsChevronLeft onClick={scrollLeft} />
                                                            )}
                                                            <div ref={sizeContainerRef} className="card-size-content">
                                                                {Object.values(product.sizes).map((size, index) => (
                                                                    <div key={index} className="card-size-box">
                                                                        {size.value}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {isScrollable && (
                                                                <BsChevronRight onClick={scrollRight} />
                                                            )}
                                                        </>
                                                    ) : (
                                                        sizeValue.length > 0 && <div className="size-box">{sizeValue}</div>
                                                    )}
                                                </div>
                                            ) : (
                                                <p className="card-price">{product.price}</p>
                                            )}
                                            <div className="color-container">
                                                {/* Displaying color circles based on propertyId */}
                                                {colorPropertiesId.map((propertyId, index) => {
                                                    const color = ProductColors[propertyId];

                                                    if (color) {
                                                        // If it's an array (multiple colors), render each color as a split circle
                                                        if (Array.isArray(color)) {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="color-circle-split"
                                                                    style={{
                                                                        background: `linear-gradient(to right, ${color[0]} 50%, ${color[1]} 50%)`
                                                                    }}
                                                                    aria-label={`Color: ${color[0]} / ${color[1]}`}
                                                                />
                                                            );
                                                        }
                                                        // If it's a single color, render just that one color
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="color-circle"
                                                                style={{ backgroundColor: color }}
                                                                aria-label={`Color: ${color}`}
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                        {/* 
                                        {quickShopVisible && quickShopProduct?.id === product.id && (
                                            <QuickShopPopup product={quickShopProduct} onClose={() => setQuickShopVisible(false)} />
                                        )}*/}
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            {hasMoreProducts && (
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <button className='show-more-button' onClick={handleLoadMoreClick}>{t('shop-ShowMore')}</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default PimBrowser;


