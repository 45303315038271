import React, { useState, useEffect } from 'react';
import { BsX } from 'react-icons/bs';
import { SlDiamond } from "react-icons/sl";

import { useTranslation } from 'react-i18next';


//import '../../css/ShoppingCart.css'
import '../../style/miniCart.scss';


const MiniCart = ({setShowMiniCart, showMiniCart,  activeCountry, activeLanguage, cart, handleQuantityChange }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (showMiniCart) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup to restore scroll on unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showMiniCart]);

    const handleCloseMiniCart = () => {
        setShowMiniCart(false);
    }

    const handleGoToCheckout = () => {
        document.location.href="/" + activeLanguage.shortCode + '-' + activeCountry.iso + '/checkout';
    }

    return (
        <>
            <div className="shoppingcart-container">
                <div className="shoppingcart-header-icon">
                    <BsX size={24} color="#000" onClick={handleCloseMiniCart}/>
                </div>
                <div className="shoppingcart-header">
                    <SlDiamond size={41} />
                    <h2 className="shoppingcart-header-text">{t('shop-AddedProducts')}</h2>
                </div>
                <div className="shoppingcart-products">
                    {cart.products.length > 0 ? (
                        cart.products.map((product) => (
                            <div key={product.productId} className="cart-item-container">
                                <div className="cart-item-image">
                                    <img src={product.image} alt={product.name} />
                                </div>
                                <div className="cart-item-details">
                                    <div className="cart-item-details-row">
                                        <h2 className="cart-item-title">{product.name}</h2>
                                        <div className="cart-item-color">
                                            <span style={{ backgroundColor: product }} className="color-box"></span>
                                        </div>
                                    </div>

                                    <p className="cart-item-price">{product.displayPrice}</p>

                                    <div className="cart-item-counter">
                                        <button onClick={() => handleQuantityChange(product.productId, 'decrease')} className="counter-button">-</button>
                                        <span className="counter-value">{product.quantity}</span>
                                        <button onClick={() => handleQuantityChange(product.productId, 'increase')} className="counter-button">+</button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="empty-cart">{t('shop-cartEmpty')}</p>
                    )}
                </div>
                <div className="cart-total-sum">
                    <p>Summa&nbsp;</p>
                    <p>{cart.displayFinalValue}</p>
                </div>
                <button className="shoppingcart-button" onClick={handleGoToCheckout}>{t('shop-ToCheckout')}</button>
                <button className="keepshopping-button"  onClick={handleCloseMiniCart}>{t('shop-ContinueShopping')}</button>
            </div>
            {showMiniCart && <div className="overlay" />}
        </>
    );
}

export default MiniCart;