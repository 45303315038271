import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSize } from "../WindowSize";

import '../../style/dropdownMenuComponent.scss'

const DropdownMenuComponent = (props) => {
    const { navigations, templates, setMenuVisible } = props;
    const windowsize = useSize();

  const gridTemplateColumns = windowsize > 1200 ? 'repeat(5, 1fr)' : 'repeat(2, 1fr)';
  const [isKollektionerActive, setIsKollektionerActive] = useState(false);
  const [isEditsActive, setIsEditsActive] = useState(true);

  const handleToggle = key => {
    if (key === "2236") {
      setIsEditsActive(true);
      setIsKollektionerActive(false);
    } else if (key === "2249") {
      setIsEditsActive(false);
      setIsKollektionerActive(true);
    }
  };

  const activeTemplateMenuUsp = templates?.find(template =>
    (isEditsActive && template.templateId === 14) ||
    (isKollektionerActive && template.templateId === 22)
  ) || null;


    let topLevelNav = navigations.map((nav, index) => {
        let secondLevelNav = Object.keys(nav.children).map(key => {
            let navItem = nav.children[key];

          if (key === "2236" || key === "2249") {
            return (
              <a key={key} onClick={() => handleToggle(key)} style={{ cursor: "pointer" }}>
                {navItem.name}
              </a>
            );
          }

            return navItem.link !== null ? (
                <NavLink to={navItem.link.url} key={key} onClick={() => setMenuVisible(false) }>
                    {navItem.name}
                </NavLink>
            ) : null;
        });

        return (
            <div key={index}>
                {secondLevelNav}
                {index !== navigations.length - 1 && (
                    <hr style={{ border: "1px solid #fff" }} />
                )}
            </div>
        );
    });

    return (
        <div className='dropdown-container'>
            {/* Left Block: Side Menu */}
            <div className='left-menu-block'>
                {/* Insert activeTemplateMenuUsp after the first menu item for mobile/tablet */}
                {topLevelNav.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className='menu-text'>
                            {item}
                        </div>
                        {windowsize <= 1070 && index === 0 && activeTemplateMenuUsp && (
                            <>
                                <div className="mobile-active-template-container">
                                    <div className="mobile-active-template">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: activeTemplateMenuUsp.data,
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </React.Fragment>
                ))}
            </div>
            {/* Right Block: Circles with Text */}
            {windowsize > 1070 && (
                <div
                    style={{
                        width: '70%',
                        gridTemplateColumns: gridTemplateColumns,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        whiteSpace: 'nowrap',
                        padding: '0 10px'
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                        }}
                    >
                        {activeTemplateMenuUsp && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: activeTemplateMenuUsp.data,
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropdownMenuComponent;
