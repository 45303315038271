import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from "react-router-dom";

import arrowProductLeft from './icon/arrowProduct.png';
import arrowProductRight from './icon/arrowProduct.png';
import { BsBookmark, BsGem, BsBookmarkFill } from "react-icons/bs";

import { ProductColors } from '../ProductColors';

import '../../style/ProductSlider.scss';

const ProductSlider = ({ values, handleFavoriteQuantityChange, favorite }) => {
    const [products, setProducts] = useState([]);
    const [currentIndexes, setCurrentIndexes] = useState([]);
    const [favorites, setFavorites] = useState({});
    const navigate = useNavigate();

    const handleFavoriteClick = (e, productId, isFavorite) => {
        e.stopPropagation();
        setFavorites((prevFavorites) => {
            const updatedFavorites = { ...prevFavorites, [productId]: !isFavorite };

            handleFavoriteQuantityChange(productId, isFavorite ? 'decrease' : 'increase');

            return updatedFavorites;
        });
    };

    useEffect(() => {
        if (favorite && favorite.products && Array.isArray(favorite.products)) {
            const favoriteIds = favorite.products.reduce((acc, item) => {
                acc[item.id] = true;
                return acc;
            }, {});
            setFavorites(favoriteIds);
        }
    }, [favorite]);

    const handleProductClick = (productUri) => {
        navigate(productUri);
    };

    const handlePreviousImage = (productIndex) => {
        setCurrentIndexes(prevIndexes =>
            prevIndexes.map((currentIndex, index) => {
                if (index === productIndex) {
                    const imageArray = products[productIndex]?.images || [];
                    return imageArray.length > 0 ? (currentIndex > 0 ? currentIndex - 1 : imageArray.length - 1) : 0;
                }
                return currentIndex;
            })
        );
    };

    const handleNextImage = (productIndex) => {
        setCurrentIndexes(prevIndexes =>
            prevIndexes.map((currentIndex, index) => {
                if (index === productIndex) {
                    const imageArray = products[productIndex]?.images || [];
                    return imageArray.length > 0 ? (currentIndex < imageArray.length - 1 ? currentIndex + 1 : 0) : 0;
                }
                return currentIndex;
            })
        );
    };

    useEffect(() => {
        fetch('/Api/Widget/System/Pim/ProductSlider/GetListedProductsJson?widgetId=' + values.id)
            .then((res) => res.json())
            .then((products) => {
                setProducts(products);
                setCurrentIndexes((prevArray) => [...prevArray, ...Array(products.length).fill(0)]);
            })
    }, [values.id])

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1540 },
            items: 5,
            slidesToSlide: 3,
            partialVisibilityGutter: 10,
        },
        desktopsmall: {
            breakpoint: { max: 1540, min: 1256 },
            items: 3,
            slidesToSlide: 3,
            partialVisibilityGutter: 50,
        },
        tablet: {
            breakpoint: { max: 1265, min: 464 },
            items: 3,
            slidesToSlide: 2,
            partialVisibilityGutter: 30,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1,
            partialVisibilityGutter: 25,
        }
    };

    const productSlider = products.map((product, index) => {

        const imageArray = Array.isArray(product.images) ? product.images : [];
        const showImage = imageArray.length > 0;
        const currentIndex = currentIndexes[index];

        const colorPropertiesId = product.properties
            .filter(prop => prop.type === 'color')
            .map(prop => prop.propertyId);

        const materialValue = product.properties
            .filter(prop => prop.type === 'material')
            .map(prop => prop.value);

        return (
            <div className='container-product' key={product.id}>
                <div className='card' onClick={() => handleProductClick(product.uri)}>
                    <div className='card-header'>
                        {imageArray.length > 1 && (
                            <>
                                <div className="arrow-wrapper">
                                    <img
                                        src={arrowProductLeft}
                                        alt="Arrow Left"
                                        className="arrow-image-left"
                                        style={{ transform: 'rotate(180deg)' }}
                                        onClick={(e) => { handlePreviousImage(index); e.stopPropagation(); }}
                                    />
                                </div>
                                <div className="arrow-wrapper">
                                    <img
                                        src={arrowProductRight}
                                        alt="Arrow Right"
                                        className="arrow-image-right"
                                        onClick={(e) => { handleNextImage(index); e.stopPropagation(); }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="product-image">
                        {showImage && (
                            <img
                                src={imageArray[currentIndex]}
                                alt={product.name}
                            />
                        )}
                        <div className="card-footer">
                            <span
                                className="icon-bookmark"
                                onClick={(e) => {
                                    handleFavoriteClick(e, product.id, favorites[product.id])
                                }}
                                style={{ cursor: "pointer" }}>
                                {favorites[product.id] ?
                                    <BsBookmarkFill className="favorite-icon filled" /> :
                                    <BsBookmark className="favorite-icon" />}
                            </span>

                            <span className="icon-gem">
                                <BsGem />
                            </span>
                            {/* 
                                <span  
                                    className={`icon-gem ${isGemSelected ? "selected" : ""}`}
                                    onClick={(e) => {
                                        setIsGemSelected(!isGemSelected);
                                        e.stopPropagation();
                                    }}
                                    style={{ cursor: "pointer", position: "relative" }}
                                >
                                    {isGemSelected && <span className="gem-background"></span>}
                                    <BsGem className="gem-icon" />
                                </span>
                            */}
                        </div>
                    </div>

                    <div className="card-details-footer">
                        <p className="card-material">{materialValue}</p>
                        <h2 className="card-title">{product.name}</h2>
                        <p className="card-price">{product.price}</p>
                        <div className="color-container">
                            {/* Displaying color circles based on propertyId */}
                            {colorPropertiesId.map((propertyId, index) => {
                                const color = ProductColors[propertyId];

                                if (color) {
                                    // If it's an array (multiple colors), render each color as a split circle
                                    if (Array.isArray(color)) {
                                        return (
                                            <div
                                                key={index}
                                                className="color-circle-split"
                                                style={{
                                                    background: `linear-gradient(to right, ${color[0]} 50%, ${color[1]} 50%)`
                                                }}
                                                aria-label={`Color: ${color[0]} / ${color[1]}`}
                                            />
                                        );
                                    }
                                    // If it's a single color, render just that one color
                                    return (
                                        <div
                                            key={index}
                                            className="color-circle"
                                            style={{ backgroundColor: color }}
                                            aria-label={`Color: ${color}`}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    });


    return (
        <div style={{ zIndex: 0, position: 'relative' }}>
            <Carousel responsive={responsive} partialVisible={true}>
                {productSlider}
            </Carousel>
        </div>
    )
}

export default ProductSlider;

